import _ from "lodash";
export function CreationTableauDetailRetraite(DebutPeriode, FinPeriode, RetraiteActivite, OldDetailRetraitePasse, OldDetailRetraiteFutur) {
  // console.log('VARS' ,DebutPeriode, FinPeriode,RetraiteActivite,OldDetailRetraitePasse,OldDetailRetraiteFutur);
  return new Promise(function (resolve, reject) {
    let DateDebutPeriode = new Date();
    const AnneeDebutPeriode = DebutPeriode.slice(4);
    DateDebutPeriode.setFullYear(DebutPeriode.slice(4), DebutPeriode.slice(2, 4) - 1, DebutPeriode.slice(0, 2));

    let DateFinPeriode = new Date();
    const AnneeFinPeriode = FinPeriode.slice(4);
    DateFinPeriode.setFullYear(FinPeriode.slice(4), FinPeriode.slice(2, 4) - 1, FinPeriode.slice(0, 2));

    DateDebutPeriode.setHours(0, 0, 0, 0);
    DateFinPeriode.setHours(24, 0, 0, 0);

    let DetailRetraitePasse = {};
    let DisplayDeplafondPass = false;
    let DetailRetraiteFutur = {};

    const FinAnneeDebutPeriode = new Date();
    if (AnneeDebutPeriode === AnneeFinPeriode) {
      FinAnneeDebutPeriode.setFullYear(FinPeriode.slice(4), FinPeriode.slice(2, 4) - 1, FinPeriode.slice(0, 2));
    } else {
      FinAnneeDebutPeriode.setFullYear(AnneeDebutPeriode, 11, 31);
    }
    FinAnneeDebutPeriode.setHours(24, 0, 0, 0);
    var delta = Math.abs(FinAnneeDebutPeriode - DateDebutPeriode) / 1000;
    var DureePeriodeAnneeDebut = Math.floor(delta / 86400);
    DureePeriodeAnneeDebut = Math.min(365, DureePeriodeAnneeDebut);

    const DebutAnneeFinPeriode = new Date();
    if (AnneeDebutPeriode === AnneeFinPeriode) {
      DebutAnneeFinPeriode.setFullYear(DebutPeriode.slice(4), DebutPeriode.slice(2, 4) - 1, DebutPeriode.slice(0, 2));
    } else {
      DebutAnneeFinPeriode.setFullYear(AnneeFinPeriode, 0, 1);
    }
    DebutAnneeFinPeriode.setHours(0, 0, 0, 0);
    var delta2 = Math.abs(DateFinPeriode - DebutAnneeFinPeriode) / 1000;
    var DureePeriodeAnneeFin = Math.floor(delta2 / 86400);
    DureePeriodeAnneeFin = Math.min(365, DureePeriodeAnneeFin);
    if (AnneeDebutPeriode < 2019) {
      //2019 A CHANGE SI ON VEUT RENDRE LES 2 TABLEAUX DYNAMIQUES (PASSE & FUTUR)

      for (var Annee = Number(AnneeDebutPeriode); Annee <= Math.min(2018, Number(AnneeFinPeriode)); Annee++) {
        DetailRetraitePasse[Annee] = {
          Salaire: OldDetailRetraitePasse[Annee] ? OldDetailRetraitePasse[Annee]["Salaire"] : 0,
          Trimestre: OldDetailRetraitePasse[Annee] ? OldDetailRetraitePasse[Annee]["Trimestre"] : 0,
          CheckboxTrimestre: OldDetailRetraitePasse[Annee] ? OldDetailRetraitePasse[Annee]["CheckboxTrimestre"] : false,

          TypeSalaire: "SalaireManu",
          DureePeriode: 365,
          CheckboxDeplafondPass: OldDetailRetraitePasse[Annee] ? OldDetailRetraitePasse[Annee]["CheckboxDeplafondPass"] : false,
        };
        if (Annee === Number(AnneeDebutPeriode)) {
          DetailRetraitePasse[Annee]["DureePeriode"] = DureePeriodeAnneeDebut;
        }
        if (Annee === Number(AnneeFinPeriode)) {
          DetailRetraitePasse[Annee]["DureePeriode"] = DureePeriodeAnneeFin;
        }

        if (
          (RetraiteActivite === "ActiviteSalarie" ||
            RetraiteActivite === "SalarieNonCadre" ||
            RetraiteActivite === "SalarieCadre" ||
            RetraiteActivite === "SalarieNonCadreCFE" ||
            RetraiteActivite === "SalarieCadreCFE" ||
            RetraiteActivite === "SalarieNonCadreAgricole" ||
            RetraiteActivite === "SalarieCadreAgricole" ||
            RetraiteActivite === "CavecSalarie" ||
            RetraiteActivite === "SalarieIrcantec") &&
          Annee < 2005
        ) {
          DisplayDeplafondPass = true;
        }
      }
    }
    if (AnneeFinPeriode >= 2019) {
      // console.log("FUTU", OldDetailRetraiteFutur);
      //REVENUS FUTUR
      for (var Annee = Math.max(AnneeDebutPeriode, Number(2019)); Annee <= Number(AnneeFinPeriode); Annee++) {
        DetailRetraiteFutur[Annee] = {
          Trimestre: OldDetailRetraiteFutur[Annee] ? OldDetailRetraiteFutur[Annee]["Trimestre"] : 0,
          TypeSalaire: "SalaireManu",
          CheckboxTrimestre: OldDetailRetraiteFutur[Annee] ? OldDetailRetraiteFutur[Annee]["CheckboxTrimestre"] : false,
        };
        if (Number(AnneeDebutPeriode) === Number(AnneeFinPeriode)) {
          DetailRetraiteFutur[Annee]["Salaire"] = OldDetailRetraiteFutur[Annee]
            ? Number((OldDetailRetraiteFutur[Annee]["Salaire"] / OldDetailRetraiteFutur[Annee]["DureePeriode"]) * DureePeriodeAnneeDebut).toFixed(0)
            : 0;
          DetailRetraiteFutur[Annee]["DureePeriode"] = DureePeriodeAnneeDebut;
        } else if (Annee === Number(AnneeDebutPeriode)) {
          DetailRetraiteFutur[Annee]["Salaire"] = OldDetailRetraiteFutur[Annee]
            ? Number((OldDetailRetraiteFutur[Annee]["Salaire"] / OldDetailRetraiteFutur[Annee]["DureePeriode"]) * DureePeriodeAnneeDebut).toFixed(0)
            : 0;
          DetailRetraiteFutur[Annee]["DureePeriode"] = DureePeriodeAnneeDebut;
        } else if (Annee === Number(AnneeFinPeriode)) {
          // console.log(DureePeriodeAnneeFin);
          DetailRetraiteFutur[Annee]["DureePeriode"] = DureePeriodeAnneeFin;
          let DernierSalaire = DetailRetraiteFutur[AnneeFinPeriode - 1] ? Number((DetailRetraiteFutur[AnneeFinPeriode - 1]["Salaire"] / 365) * DureePeriodeAnneeFin).toFixed(0) : 0;
          DetailRetraiteFutur[Annee]["Salaire"] = DernierSalaire;
        } else {
          DetailRetraiteFutur[Annee]["Salaire"] = OldDetailRetraiteFutur[Annee]
            ? Number((OldDetailRetraiteFutur[Annee]["Salaire"] / OldDetailRetraiteFutur[Annee]["DureePeriode"]) * 365).toFixed(0)
            : 0;
          DetailRetraiteFutur[Annee]["DureePeriode"] = 365;
        }
        // console.log("aNN", Annee, OldDetailRetraiteFutur[Annee], OldDetailRetraiteFutur[Annee] ? OldDetailRetraiteFutur[Annee]["Salaire"] : 0);
      }
    } else {
      // console.log("ELSE", AnneeFinPeriode);
      DetailRetraiteFutur = {};
    }
    // console.log("Det", DetailRetraiteFutur);
    resolve({ DetailRetraitePasse: DetailRetraitePasse, DisplayDeplafondPass: DisplayDeplafondPass, DetailRetraiteFutur: DetailRetraiteFutur });
  });
}

export function ModificationCarriere(ModifCarriere, CurrentYear, DateDepartRetraite) {
  return new Promise(function (resolve, reject) {
    ModifCarriere.forEach((Periode) => {
      let DerniereAnneeRenseigne = Math.max(CurrentYear - 1, Number(Object.keys(Periode.DetailRetraiteFutur)[Object.keys(Periode.DetailRetraiteFutur).length - 1]));
      CreationTableauDetailRetraite(Periode.Debut, DateDepartRetraite.replace(/\//g, ""), Periode.Nature, {}, Periode.DetailRetraiteFutur).then((CreationDetailRetraiteFutur) => {
        let DerniereAnneeNouvellePeriode = Number(Object.keys(CreationDetailRetraiteFutur.DetailRetraiteFutur)[Object.keys(CreationDetailRetraiteFutur.DetailRetraiteFutur).length - 1]);
        // console.log('MIN',CreationDetailRetraiteFutur,DerniereAnneeNouvellePeriode,DerniereAnneeRenseigne, Math.min(DerniereAnneeNouvellePeriode,DerniereAnneeRenseigne))

        // console.log('OLD',Periode.DetailRetraiteFutur,'NEW',CreationDetailRetraiteFutur.DetailRetraiteFutur)
        const DetailRetraiteFutur = RevenusFutur(
          Math.min(DerniereAnneeNouvellePeriode, DerniereAnneeRenseigne),
          CreationDetailRetraiteFutur.DetailRetraiteFutur,
          CreationDetailRetraiteFutur.DetailRetraiteFutur[Math.min(DerniereAnneeNouvellePeriode, DerniereAnneeRenseigne)].Salaire,
          Periode.MontantEvolution
        );

        // if(DerniereAnneeNouvellePeriode<=DerniereAnneeRenseigne)
        // {
        //   Object.keys(DetailRetraiteFutur).forEach(function(AnneeTableau) {
        //      let NewRevenus = (Number( DetailRetraiteFutur[AnneeTableau]["Salaire"]) / 365) * DetailRetraiteFutur[AnneeTableau]["DureePeriode"];
        //       NewRevenus = Number(NewRevenus).toFixed(0);
        //       DetailRetraiteFutur[AnneeTableau]["Salaire"] = NewRevenus;
        //       console.log('PINK',AnneeTableau,NewRevenus)
        //   })
        // }

        Periode.Fin = DateDepartRetraite.replace(/\//g, "");
        Periode.DetailRetraiteFutur = DetailRetraiteFutur;
        Periode.Detail = {
          ...Periode.DetailRetraitePasse,
          ...DetailRetraiteFutur,
        };
      });
      resolve(ModifCarriere);
    });
  });
}
export function RevenusFutur(Annee, DetailRetraiteFutur, Salaire, MontantEvolution) {
  let NewDetailRetraite = _.cloneDeep(DetailRetraiteFutur);
  NewDetailRetraite[Annee]["Salaire"] = Salaire;

  let ValueEvolution = MontantEvolution;

  let NewRevenus = (Number(Salaire) / NewDetailRetraite[Annee]["DureePeriode"]) * 365;
  // let NewRevenus = Number(Salaire);
  Object.keys(NewDetailRetraite).forEach(function (AnneeTableau) {
    if (Number(AnneeTableau) > Number(Annee)) {
      // console.log('NR',NewRevenus)
      NewRevenus = Number(NewRevenus) + Number(NewRevenus) * Number(ValueEvolution / 100);
      NewRevenus = (Number(NewRevenus) / 365) * NewDetailRetraite[AnneeTableau]["DureePeriode"];
      NewRevenus = Number(NewRevenus).toFixed(0);
      NewDetailRetraite[AnneeTableau]["Salaire"] = NewRevenus;
    }
    // if (Number(AnneeTableau) === Number(Annee)) {
    //   console.log('NR',NewRevenus)
    //   NewRevenus = (Number(NewRevenus) / 365) * NewDetailRetraite[AnneeTableau]["DureePeriode"];
    //   NewRevenus = Number(NewRevenus).toFixed(0);
    //   NewDetailRetraite[AnneeTableau]["Salaire"] = NewRevenus;
    //   // console.log('AT',AnneeTableau,NewRevenus,NewDetailRetraite[AnneeTableau]["DureePeriode"])
    // }
  });

  return NewDetailRetraite;
}
export function VersementContrat(Annee, DetailVersement, Versement, MontantEvolution) {
  let NewDetailVersement = DetailVersement;
  NewDetailVersement[Annee]["Versement"] = Versement;
  let ValueEvolution = MontantEvolution;

  // let NewRevenus = (Number(Salaire) / NewDetailRetraite[Annee]["DureePeriode"]) * 365;
  let NewVersement = Number(Versement);
  Object.keys(NewDetailVersement).forEach(function (AnneeTableau) {
    if (Number(AnneeTableau) > Number(Annee)) {
      NewVersement = Number(NewVersement) + Number(NewVersement) * Number(ValueEvolution / 100);
      NewVersement = Number(NewVersement).toFixed(0);
      NewDetailVersement[AnneeTableau]["Versement"] = NewVersement;
      // console.log('AT',AnneeTableau,NewRevenus,NewDetailRetraite[AnneeTableau]["DureePeriode"])
    }
    // if (Number(AnneeTableau) === Number(Annee)) {
    //   console.log('NR',NewRevenus)
    //   NewRevenus = (Number(NewRevenus) / 365) * NewDetailRetraite[AnneeTableau]["DureePeriode"];
    //   NewRevenus = Number(NewRevenus).toFixed(0);
    //   NewDetailRetraite[AnneeTableau]["Salaire"] = NewRevenus;
    //   // console.log('AT',AnneeTableau,NewRevenus,NewDetailRetraite[AnneeTableau]["DureePeriode"])
    // }
  });

  return NewDetailVersement;
}

export function HandlerVerifPointRetraite(TablPoints, Carriere) {
  let TableauPoints = { ...TablPoints };

  let ListeNature = Carriere.filter(
    (Carriere) =>
      Carriere.Nature === "Carcdsf" ||
      Carriere.Nature === "Carcdsf2" ||
      Carriere.Nature === "Carmf" ||
      Carriere.Nature === "Carpimko" ||
      Carriere.Nature === "Carpv" ||
      Carriere.Nature === "Cavec" ||
      Carriere.Nature === "Crn" ||
      Carriere.Nature === "Cavamac" ||
      Carriere.Nature === "Cavp" ||
      Carriere.Nature === "Cavom" ||
      Carriere.Nature === "MicroCipav" ||
      Carriere.Nature === "Cipav"
  );
  TableauPoints.RetraiteBaseCnavpl = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteBaseCnavpl;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "FonctionPublique");
  TableauPoints.RetraiteCompFonctionPublique = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompFonctionPublique;

  ListeNature = Carriere.filter(
    (Carriere) =>
      Carriere.Nature === "ActiviteSalarie" ||
      Carriere.Nature === "SalarieNonCadre" ||
      Carriere.Nature === "SalarieCadre" ||
      Carriere.Nature === "SalarieNonCadreCFE" ||
      Carriere.Nature === "SalarieCadreCFE" ||
      Carriere.Nature === "SalarieNonCadreAgricole" ||
      Carriere.Nature === "CavecSalarie" ||
      Carriere.Nature === "SalarieCadreAgricole"
  );
  TableauPoints.RetraiteCompArrco = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompArrco;
  TableauPoints.RetraiteCompArrcoAvant1999 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompArrcoAvant1999;
  TableauPoints.RetraiteCompArrco19992011 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompArrco19992011;
  TableauPoints.RetraiteCompArrcoApres2012 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompArrcoApres2012;

  ListeNature = Carriere.filter(
    (Carriere) =>
      Carriere.Nature === "ActiviteSalarie" ||
      Carriere.Nature === "SalarieCadre" ||
      Carriere.Nature === "SalarieCadreCFE" ||
      Carriere.Nature === "SalarieCadreAgricole" ||
      Carriere.Nature === "CavecSalarie"
  );
  TableauPoints.RetraiteCompAgircTB = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompAgircTB;
  TableauPoints.RetraiteCompAgircTBAvant2012 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompAgircTBAvant2012;
  TableauPoints.RetraiteCompAgircTBApres2012 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompAgircTBApres2012;
  TableauPoints.RetraiteCompAgircTC = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompAgircTC;
  TableauPoints.RetraiteCompAgircTCAvant2012 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompAgircTCAvant2012;
  TableauPoints.RetraiteCompAgircTC20122015 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompAgircTC20122015;
  TableauPoints.RetraiteCompAgircTC2016 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompAgircTC2016;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "ActiviteSalarie" || Carriere.Nature === "SalarieIrcantec");
  TableauPoints.RetraiteCompIrcantec = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompIrcantec;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Msa");
  TableauPoints.RetraiteProportionnelleMsa = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteProportionnelleMsa;
  TableauPoints.RetraiteCompMsa = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompMsa;

  ListeNature = Carriere.filter(
    (Carriere) =>
      Carriere.Nature === "RsiCommercant" || Carriere.Nature === "RsiArtisan" || Carriere.Nature === "MicroRSI" || Carriere.Nature === "MicroBIC" || Carriere.Nature === "MicroBNC"
  );
  TableauPoints.RetraiteTotalRCI = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteTotalRCI;
  TableauPoints.RetraiteCompRCI = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompRCI;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "RsiArtisan");
  TableauPoints.RetraiteCompRsiArtisanAvant1979 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompRsiArtisanAvant1979;
  TableauPoints.RetraiteCompRsiArtisanAvant1997 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompRsiArtisanAvant1997;
  TableauPoints.RetraiteCompRsiArtisanApres1997 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompRsiArtisanApres1997;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "RsiCommercant" || Carriere.Nature === "MicroRSI" || Carriere.Nature === "MicroBIC" || Carriere.Nature === "MicroBNC");
  TableauPoints.PointRetraiteCompRsiCommercantAvant2013 = ListeNature.length === 0 ? 0 : TableauPoints.PointRetraiteCompRsiCommercantAvant2013;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Carcdsf");
  TableauPoints.RetraiteCompCarcdsf = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCarcdsf;
  TableauPoints.RetraiteSuppCarcdsf = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarcdsf;
  TableauPoints.RetraiteSuppCarcdsfAvant1995 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarcdsfAvant1995;
  TableauPoints.RetraiteSuppCarcdsf19952005 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarcdsf19952005;
  TableauPoints.RetraiteSuppCarcdsfApres2006 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarcdsfApres2006;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Carcdsf2");
  TableauPoints.RetraiteCompCarcdsf2 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCarcdsf2;
  TableauPoints.RetraiteSuppCarcdsf2 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarcdsf2;
  TableauPoints.RetraiteSuppCarcdsf2Avant1995 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarcdsf2Avant1995;
  TableauPoints.RetraiteSuppCarcdsf219952005 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarcdsf219952005;
  TableauPoints.RetraiteSuppCarcdsf2Apres2006 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarcdsf2Apres2006;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Carmf");
  TableauPoints.RetraiteCompCarmf = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCarmf;
  TableauPoints.RetraiteSuppCarmf = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarmf;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Carpimko");
  TableauPoints.RetraiteCompCarpimko = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCarpimko;
  TableauPoints.RetraiteSuppCarpimko = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarpimko;
  TableauPoints.RetraiteSuppCarpimko19601975 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarpimko19601975;
  TableauPoints.RetraiteSuppCarpimko19761987 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarpimko19761987;
  TableauPoints.RetraiteSuppCarpimko19881997 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarpimko19881997;
  TableauPoints.RetraiteSuppCarpimko19982005 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarpimko19982005;
  TableauPoints.RetraiteSuppCarpimkoApres2005 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCarpimkoApres2005;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Carpv");
  TableauPoints.RetraiteCompCarpv = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCarpv;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Crn");
  TableauPoints.RetraiteCompCrnSectionB = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCrnSectionB;
  TableauPoints.RetraiteCompCrnSectionC = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCrnSectionC;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Cavamac");
  TableauPoints.RetraiteCompCavamac = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCavamac;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Cavec");
  TableauPoints.RetraiteCompCavec = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCavec;
  TableauPoints.RetraiteCompCavecAvant2009 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCavecAvant2009;
  TableauPoints.RetraiteCompCavecApres2009 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCavecApres2009;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Cipav" || Carriere.Nature === "MicroCipav");
  TableauPoints.RetraiteCompCipav = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCipav;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "RsiCommercant" && Carriere.ParamCotisations.Ravgdt === "Oui");
  TableauPoints.RetraiteCompRavgdt = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompRavgdt;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Cavom");
  TableauPoints.RetraiteCompCavom = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCavom;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Cavp");
  TableauPoints.TrimestreCompCavp = ListeNature.length === 0 ? 0 : TableauPoints.TrimestreCompCavp;
  TableauPoints.RetraiteSuppCavp = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavp;
  TableauPoints.RetraiteSuppCavp19771981 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavp19771981;
  TableauPoints.RetraiteSuppCavp19821986 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavp19821986;
  TableauPoints.RetraiteSuppCavp19871991 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavp19871991;
  TableauPoints.RetraiteSuppCavp19921996 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavp19921996;
  TableauPoints.RetraiteSuppCavp19972001 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavp19972001;
  TableauPoints.RetraiteSuppCavp20022005 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavp20022005;
  TableauPoints.RetraiteSuppCavpApres2006 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavpApres2006;
  TableauPoints.RetraiteSuppCavpApres2019 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavpApres2019;
  TableauPoints.RetraiteSuppCavp2021 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavp2021;
  TableauPoints.RetraiteSuppCavp2022 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavp2022;
  TableauPoints.RetraiteSuppCavp2023 = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteSuppCavp2023;
  TableauPoints.RetraiteCompCavpCapi = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCavpCapi;

  ListeNature = Carriere.filter((Carriere) => Carriere.Nature === "Cnbf");
  TableauPoints.RetraiteCompCnbf = ListeNature.length === 0 ? 0 : TableauPoints.RetraiteCompCnbf;

  //TOTAUX AFFICHES
  let TotalArrco = Number(TableauPoints.RetraiteCompArrcoAvant1999) + Number(TableauPoints.RetraiteCompArrco19992011) + Number(TableauPoints.RetraiteCompArrcoApres2012);
  TableauPoints["RetraiteCompArrco"] = Number(TotalArrco).toFixed(2);
  let TotalAgircTB = Number(TableauPoints.RetraiteCompAgircTBAvant2012) + Number(TableauPoints.RetraiteCompAgircTBApres2012);
  TableauPoints["RetraiteCompAgircTB"] = TotalAgircTB;
  let TotalAgircTC = Number(TableauPoints.RetraiteCompAgircTCAvant2012) + Number(TableauPoints.RetraiteCompAgircTC20122015) + Number(TableauPoints.RetraiteCompAgircTC2016);
  TableauPoints["RetraiteCompAgircTC"] = TotalAgircTC;
  let TotalCavec = Number(TableauPoints.RetraiteCompCavecAvant2009) + Number(TableauPoints.RetraiteCompCavecApres2009);
  TableauPoints["RetraiteCompCavec"] = TotalCavec;
  let TotalCarcdsf = Number(TableauPoints.RetraiteSuppCarcdsfAvant1995) + Number(TableauPoints.RetraiteSuppCarcdsf19952005) + Number(TableauPoints.RetraiteSuppCarcdsfApres2006);
  TableauPoints["RetraiteSuppCarcdsf"] = TotalCarcdsf;
  let TotalCarcdsf2 = Number(TableauPoints.RetraiteSuppCarcdsf2Avant1995) + Number(TableauPoints.RetraiteSuppCarcdsf219952005) + Number(TableauPoints.RetraiteSuppCarcdsf2Apres2006);
  TableauPoints["RetraiteSuppCarcdsf2"] = TotalCarcdsf2;
  let TotalCarpimko =
    Number(TableauPoints.RetraiteSuppCarpimko19601975) +
    Number(TableauPoints.RetraiteSuppCarpimko19761987) +
    Number(TableauPoints.RetraiteSuppCarpimko19881997) +
    Number(TableauPoints.RetraiteSuppCarpimko19982005) +
    Number(TableauPoints.RetraiteSuppCarpimkoApres2005);
  TableauPoints["RetraiteSuppCarpimko"] = TotalCarpimko;
  let TotalCavp =
    Number(TableauPoints.RetraiteSuppCavp19771981) +
    Number(TableauPoints.RetraiteSuppCavp19821986) +
    Number(TableauPoints.RetraiteSuppCavp19871991) +
    Number(TableauPoints.RetraiteSuppCavp19921996) +
    Number(TableauPoints.RetraiteSuppCavp19972001) +
    Number(TableauPoints.RetraiteSuppCavp20022005) +
    Number(TableauPoints.RetraiteSuppCavpApres2006) +
    Number(TableauPoints.RetraiteSuppCavpApres2019) +
    Number(TableauPoints.RetraiteSuppCavp2021) +
    Number(TableauPoints.RetraiteSuppCavp2022) +
    Number(TableauPoints.RetraiteSuppCavp2023);

  TableauPoints["RetraiteSuppCavp"] = TotalCavp;
  let TotalRsiArtisan = Number(TableauPoints.RetraiteCompRsiArtisanAvant1997) + Number(TableauPoints.RetraiteCompRsiArtisanApres1997);
  TableauPoints["RetraiteCompRsiArtisan"] = TotalRsiArtisan;
  let TotalRCI = Number(TableauPoints.RetraiteCompRsiArtisan) + Number(TableauPoints.PointRetraiteCompRsiCommercantAvant2013) + Number(TableauPoints.RetraiteCompRCI);
  TableauPoints["RetraiteTotalRCI"] = TotalRCI;

  return TableauPoints;
}
